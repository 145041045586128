.eventList {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    flex-flow: column;
    list-style-type: none;

    li {
        display: inline-block;
    }

    input:first-child {
        margin-top: 40px;
    }
}

.wrapper {
    position: relative;
    width: 100%;
}

.utility {
    position: absolute;
    top: 0;
    right: 0;
}

.selector {
    margin-top: 10px;

    &:first-child {
        margin-top: 40px;
    }
}
