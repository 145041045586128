.houses {
    display: grid;
    padding-top: 20px;
    gap: 10px;
}

.house {
    padding: 20px 10px;
    border-radius: 20px;
    background-image: url('../../assets/img/connect-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
}

.houseTitle {
    padding-bottom: 5px;
    font-size: 1.2em;
    text-decoration: underline;;
}
