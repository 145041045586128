.plan {
    padding: 20px 10px;
    border-radius: 20px;
    background-image: url('../../assets/img/connect-background.svg');
    background-repeat: no-repeat;
    background-size: cover;

    td {
        border-radius: 20px;
        background-color: #ffffff;

        &:last-child {
            min-height: 50px;
        }
    }
}

.table {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-auto-flow: row;
    gap: 10px;

    .label,
    .entry {
        display: flex;
        padding: 5px 15px;
        border-radius: 20px;
        flex-flow: column;
        background-color: #ffffff;
    }

    .label {
        height: 45px;
        justify-content: center;
        align-items: center;
    }

    .entry {
        min-height: 60px;
    }
}

.column {
    display: grid;
    grid-auto-flow: row;
    gap: 10px;
}

.columnLabel {
    border-bottom: #000000 solid 1px;
    margin-bottom: 10px;
    text-align: center;
}
