@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

body{box-sizing:border-box;background-color:#f5f5f8;font-family:"Quicksand",sans-serif}
.Header_header__MbTxY{display:grid;padding:20px 0;grid-template-columns:40px 1fr 40px}.Header_title__1F92a{text-align:center}
.Hamburger_hamburger__FmRNV{cursor:pointer}.Hamburger_hamburgerPopup__uCurW{position:absolute;z-index:999;display:flex;width:300px;padding:30px 20px;border:1px solid #000;border-radius:20px;flex-flow:column;background-color:#fff}.Hamburger_hamburgerPopup__uCurW:not(.Hamburger_open__LSbGp){display:none}.Hamburger_item__3wclC{color:#000;font-size:1.3em;text-decoration:none}
.WeeklySchedule_eventList__Yb3Ec{display:flex;padding-left:0;margin-bottom:0;flex-flow:column;list-style-type:none}.WeeklySchedule_eventList__Yb3Ec li{display:inline-block}.WeeklySchedule_eventList__Yb3Ec input:first-child{margin-top:40px}.WeeklySchedule_wrapper__ZRJZk{position:relative;width:100%}.WeeklySchedule_utility__chZCo{position:absolute;top:0;right:0}.WeeklySchedule_selector__k3NaL{margin-top:10px}.WeeklySchedule_selector__k3NaL:first-child{margin-top:40px}
.WeekSchedule_plan__2G6cy{padding:20px 10px;border-radius:20px;background-image:url(/static/media/connect-background.f39ae24f.svg);background-repeat:no-repeat;background-size:cover}.WeekSchedule_plan__2G6cy td{border-radius:20px;background-color:#fff}.WeekSchedule_plan__2G6cy td:last-child{min-height:50px}.WeekSchedule_table__l-8PD{display:grid;grid-template-columns:60px 1fr;grid-auto-flow:row;grid-gap:10px;gap:10px}.WeekSchedule_table__l-8PD .WeekSchedule_label__2OcWj,.WeekSchedule_table__l-8PD .WeekSchedule_entry__35DYm{display:flex;padding:5px 15px;border-radius:20px;flex-flow:column;background-color:#fff}.WeekSchedule_table__l-8PD .WeekSchedule_label__2OcWj{height:45px;justify-content:center;align-items:center}.WeekSchedule_table__l-8PD .WeekSchedule_entry__35DYm{min-height:60px}.WeekSchedule_column__3JjFz{display:grid;grid-auto-flow:row;grid-gap:10px;gap:10px}.WeekSchedule_columnLabel__nY7yV{border-bottom:#000 solid 1px;margin-bottom:10px;text-align:center}
.CleaningSchedule_personList__2nSAd{display:flex;padding-left:0;margin-bottom:0;flex-flow:column;list-style-type:none}.CleaningSchedule_personList__2nSAd li{display:inline-block}.CleaningSchedule_isPerson__1XFZI{background-color:#00aeee6c}.CleaningSchedule_wrapper__fBLDY{position:relative;width:100%}.CleaningSchedule_utility__3gyau{position:absolute;top:0;right:0}.CleaningSchedule_selector__1ctMc{margin-top:10px}.CleaningSchedule_selector__1ctMc:first-child{margin-top:40px}
.MealPlan_personList__3JCzb{display:flex;min-height:40px;padding-left:0;margin-bottom:0;flex-flow:column;list-style-type:none}.MealPlan_personList__3JCzb li{display:inline-block}.MealPlan_isPerson__ghp9D{background-color:#00aeee6c}.MealPlan_wrapper__1jWKu{position:relative;width:100%}.MealPlan_utility__1GU9B{position:absolute;top:0;right:0}.MealPlan_selector__36ggZ{margin-top:10px}.MealPlan_selector__36ggZ:first-child{margin-top:40px}
.OfferList_offerList__2xE34{display:grid;grid-gap:20px;gap:20px}.OfferList_item__38-Rv{position:relative;display:grid;min-height:100px;padding:10px 20px;padding-left:30px;border-radius:20px;grid-template-columns:1fr 40px;align-items:center;background-color:#fff}.OfferList_itemButton__oOTIR{display:flex;width:40px;min-height:40px;padding:3px;border-radius:20px;flex-direction:column;justify-content:center;align-items:center;background-color:#00b0ee;color:#fff}.OfferList_button__jz_Qe{display:flex;padding:0;border:unset;justify-content:center;align-items:center;background-color:transparent}.OfferList_button__jz_Qe>*{width:100%;height:100%}.OfferList_delete__3o04T{position:absolute;top:50%;left:5px;-webkit-transform:translateY(-50%);transform:translateY(-50%)}
.Order_searchBarWrapper__2eixl{position:relative;width:100%;margin-bottom:30px}.Order_searchBar__1yluL{width:100%;height:40px;padding-right:45px;padding-left:15px;border:unset;border-radius:5px}.Order_searchIcon__1x5P2{position:absolute;top:50%;right:10px;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.Order_input__2DwCG{padding:5px 15px;border:unset;border-radius:99px;background-color:#efeeee}
.ErrorToast_toast__2evRF{position:fixed;z-index:99999;top:20px;left:50%;background-color:#dc3545;-webkit-transform:translateX(-50%);transform:translateX(-50%)}.ErrorToast_toastHeader__3G2K9 [class*=close]{display:flex;width:30px;height:30px;border:0;border-radius:99px;justify-content:center;align-items:center;background-color:unset;font-size:20px}.ErrorToast_toastHeader__3G2K9 [class*=sr-only]{display:none}.ErrorToast_toastBody__1UoHy{color:#fff}
.GroceryList_offerList__15zsp{display:grid;grid-gap:20px;gap:20px}.GroceryList_item__1o6ia{display:grid;min-height:100px;padding:10px 20px;border-radius:20px;grid-template-columns:1fr 40px 40px;align-items:center;background-color:#fff}.GroceryList_itemButton__3h9Va{display:flex;width:40px;min-height:40px;padding:3px;border:#00b0ee solid 2px;border-radius:20px;flex-direction:column;justify-content:center;align-items:center;color:#00b0ee}.GroceryList_button__1vQGZ{display:flex;padding:0;border:unset;justify-content:center;align-items:center;background-color:transparent}.GroceryList_button__1vQGZ>*{width:100%;height:100%}
.CrudList_crudList__DJr4-{display:grid;grid-gap:20px;gap:20px}.CrudList_item__15Zvf{display:grid;min-height:100px;padding:10px 20px;border-radius:20px;grid-template-columns:1fr 40px 40px;align-items:center;background-color:#fff;cursor:pointer}
.Popup_popupWrapper__228xL{position:absolute;z-index:9999;top:0;right:0;bottom:0;left:0;background-color:rgba(0,0,0,.5)}.Popup_popup__2SgTe{position:absolute;top:50%;left:50%;width:90%;max-width:800px;max-height:600px;padding:10px;border-radius:9px;background-color:#fff;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}.Popup_popupControls__5cn3k{display:flex;justify-content:flex-end}.Popup_popupControl__1h1wc{width:30px !important;height:30px !important;cursor:pointer}.Popup_popupContent__KSHnr{padding:20px 10px}
.GroceryHouseList_list__28VcW{display:grid;grid-gap:20px;gap:20px}.GroceryHouseList_item__1_IpT{display:grid;padding:10px 20px;border-radius:20px;grid-template-columns:1fr 40px;align-items:center;background-color:#fff}
.HousePlan_houses__1maIR{display:grid;padding-top:20px;grid-gap:10px;gap:10px}.HousePlan_house__oRJnY{padding:20px 10px;border-radius:20px;background-image:url(/static/media/connect-background.f39ae24f.svg);background-repeat:no-repeat;background-size:cover;color:#fff}.HousePlan_houseTitle__3-JY5{padding-bottom:5px;font-size:1.2em;text-decoration:underline}
