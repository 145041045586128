@import '../../styles/globals';

.offerList {
    display: grid;
    gap: 20px;
}

.item {
    display: grid;
    min-height: 100px;
    padding: 10px 20px;
    border-radius: $border-radius;
    grid-template-columns: 1fr 40px 40px;
    align-items: center;
    background-color: #ffffff;
}

.itemButton {
    display: flex;
    width: 40px;
    min-height: 40px;
    padding: 3px;
    border: $color-accent solid 2px;
    border-radius: $border-radius;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-accent;
}

.button {
    display: flex;
    padding: 0;
    border: unset;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    >* {
        width: 100%;
        height: 100%;
    }
}
