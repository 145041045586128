.popupWrapper {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 800px;
    max-height: 600px;
    padding: 10px;
    border-radius: 9px;
    background-color: #ffffff;
    transform: translate(-50%, -50%);
}

.popupControls {
    display: flex;
    justify-content: flex-end;
}

.popupControl {
    $size: 30px;

    width: $size !important;
    height: $size !important;
    cursor: pointer;
}

.popupContent {
    padding: 20px 10px;
}
