.toast {
    position: fixed;
    z-index: 99999;
    top: 20px;
    left: 50%;
    background-color: rgba(220, 53, 69, 1);
    transform: translateX(-50%);
}

.toastHeader {

    [class*='close'] {
        display: flex;
        width: 30px;
        height: 30px;
        border: 0;
        border-radius: 99px;
        justify-content: center;
        align-items: center;
        background-color: unset;
        font-size: 20px;
    }

    [class*='sr-only'] {
        display: none;
    }
}


.toastBody {
    color: #ffffff;
}
