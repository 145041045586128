@import '../../styles/globals';

.crudList {
    display: grid;
    gap: 20px;
}

.listMenu {

}

.item {
    display: grid;
    min-height: 100px;
    padding: 10px 20px;
    border-radius: $border-radius;
    grid-template-columns: 1fr 40px 40px;
    align-items: center;
    background-color: #ffffff;
    cursor: pointer;
}
