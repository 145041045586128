@import '../../styles/globals';

.list {
    display: grid;
    gap: 20px;
}

.item {
    display: grid;
    padding: 10px 20px;
    border-radius: $border-radius;
    grid-template-columns: 1fr 40px;
    align-items: center;
    background-color: #ffffff;
}
