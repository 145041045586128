.hamburger {
    cursor: pointer;
}

.hamburgerPopup {
    position: absolute;
    z-index: 999;
    display: flex;
    width: 300px;
    padding: 30px 20px;
    border: 1px solid #000000;
    border-radius: 20px;
    flex-flow: column;
    background-color: #ffffff;

    &:not(.open) {
        display: none;
    }
}

.item {
    color: #000000;
    font-size: 1.3em;
    text-decoration: none;
}
