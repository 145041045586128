.searchBarWrapper {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}

.searchBar {
    width: 100%;
    height: 40px;
    padding-right: 45px;
    padding-left: 15px;
    border: unset;
    border-radius: 5px;
}

.searchIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.input {
    padding: 5px 15px;
    border: unset;
    border-radius: 99px;
    background-color: #efeeee;
}
